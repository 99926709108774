<template>
  <div class="p-recent">
    <h1 cass="text--primary text-center red--text">Recent Templates</h1>
    <p>See examples of templates that have been created below.</p>
    <div class="p-recent__examples">
      <v-row>
        <v-col
          class="p-recent__example"
          cols="4"
          v-for="template of templates" :key="template['.uid']"
        >
          <router-link class="p-recent__example--link" :to="getTemplateUrl(template)">
            <v-card>
              <v-card-text>
                <!-- Implement categories -->
                <div class="p-recent-category">Politicial</div>
                <p class="display-1 text--primary" v-if="template.title">
                  {{ template.title }}
                </p>
                <div class="text--primary" v-if="template.description">
                  {{ template.description }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="deep-purple accent-4"
                  block
                >
                  View template
                </v-btn>
              </v-card-actions>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </div>
    <div id="rendered" v-if="rendered"></div>
  </div>
</template>

<script>
import slugify from '@/utils/slug'

import { firestore } from '@/plugins/firebase'

export default {
  name: 'Recent',

  data () {
    return {
      templates: [],
      rendered: false
    }
  },

  mounted () {
    this.getData()
  },

  methods: {
    async getData () {
      const collection = await firestore.collection('templates').orderBy('createdAt', 'desc').get()
      this.templates = collection.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id
        }
      })
      this.rendered = true
    },
    getTemplateUrl (template) {
      const slug = slugify(template.title)

      return { name: 'Template', params: { templateId: template.id, slug } }
    }
  }
}
</script>

<style lang="scss">
.p-recent {
  &__example {
    &--link {
      text-decoration: none;
      display: block;
      &:hover {

      }
    }
  }
}
</style>
